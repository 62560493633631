import profile from "./assets/profile.webp";
import html from "./assets/techstack/html.png";
import css from "./assets/techstack/css.png";
import laravel from "./assets/techstack/laravel.png";
import PLSQL from "./assets/techstack/PLSQL.png";
import js from "./assets/techstack/js.png";
import react from "./assets/techstack/react.png";
import nodejs from "./assets/techstack/nodejs.png";
import express from "./assets/techstack/express.png";
import mongo from "./assets/techstack/mongo.png";
import tailwind from "./assets/techstack/tailwind.png";
import bootstrap from "./assets/techstack/bootstrap.png";
import vscode from "./assets/techstack/vscode.png";
import github from "./assets/techstack/github.png";
import git from "./assets/techstack/git.png";
import npm from "./assets/techstack/npm.png";
import postman from "./assets/techstack/postman.png";
import figma from "./assets/techstack/figma.png";
import projectImage1 from "./assets/projects/project1.png";
import projectImage2 from "./assets/projects/INTERNAT - Google Chrome 21_10_2024 00_38_00.png";
import projectImage3 from "./assets/projects/scrapy.png";
import projectImage4 from "./assets/projects/wink.png";
import projectImage5 from "./assets/projects/fr.png";
import projectImage6 from "./assets/projects/arkx.png";
import projectImage7 from "./assets/projects/karim.png";
import projectImage8 from "./assets/projects/mern.jpeg";
import projectImage9 from "./assets/projects/fl.png";
import projectImage10 from "./assets/projects/pharmacy.png";




export const personalDetails = {
  name: "Anas Akil",
  img: profile,
  cv:'./assets/cv.pdf',
  modelPath: './assets/scene.gltf', 
  about: `Anas Akil, graduate in web full stack   development, I am determined
  to create innovative web solutions thanks to my solid expertise in
  front-end and back-end technologies. My goal is to provide value
  added to stimulating projects.`,
};



export const ExperienceDetails = [
  {
    Position: "Developer full stack php, wordpress,devops",
    Company: `point virgul`,
    Location: "kenitra",
    Type: "Stage",
    Duration: "octobre 2024",
  },
  {
    Position: "Developer full stack, Flutter ,wordpress,devops",
    Company: `Profficiis`,
    Location: "Rabat",
    Type: "Stage",
    Duration: "juin 2024",
  },
  {
    Position: "MERN Developer Operational Internship",
    Company: `Ark-x`,
    Location: "Rabat",
    Type: "Stage",
    Duration: "feb 2024",
  },
  {
    Position: " Stage Developer LARAVEL REACT ",
    Company: `TRUBINAL`,
    Location: "Salé",
    Type: "Stage",
    Duration: "feb 2023",
  },


];

export const eduDetails = [
  {  Position: "MERN Full Stack",
    Company: `JOBINTECH`,
    Location: "Rabat",
    Duration: "feb 2024 ",
  
  },
  {
  Position: "Full stack Developer ",
    Company: "ISTA",
    Location: "Hay salam,Salé",
    Duration: "2023",
  },  
];

export const techStackDetails = {
  html: html,
  css: css,
  js: js,
  laravel: laravel,
  react: react,
  nodejs: nodejs,
  express: express,
  mongo: mongo,
  tailwind: tailwind,
  bootstrap: bootstrap,
  PLSQL: PLSQL,
  vscode: vscode,
  postman: postman,
  npm: npm,
  git: git,
  github: github,
  figma: figma,
};

export const projectDetails = [
  {
    title: "Media Downloader",
    image: projectImage1,
    description: `Media Downloader is a web application built using React for the frontend and Flask for the backend.
     It allows users to download videos and audio files (MP3) from various online sources. The application provides a user-friendly interface where users can enter the URL of the media they wish to download, select the desired format,
     and initiate the download process.`,
    techstack: "react ,flask , python",
    previewLink: "https://github.com/anasakil/projet-download",
    githubLink: "https://github.com/anasakil/projet-download",
  },
  {
    title: "l9a pharmacy",
    image:projectImage10,
    description: `The Pharmacy in Weekend app is a user-friendly mobile application designed to help users effortlessly locate nearby pharmacies during the weekend. Utilizing interactive maps, the app displays pharmacy locations and provides detailed information, including each pharmacy's name, address, and contact details. Users can seamlessly switch between a map view and a list view, allowing for easy navigation and choice of their preferred method of finding pharmacies. With its clean interface and intuitive navigation, this app serves as a valuable tool for anyone seeking pharmaceutical services, ensuring they can access the help they need, right when they need it..`,
    techstack: "Flutter , mapbox ,provider , node js , exspress , mongodb ",
    previewLink: "https://github.com/anasakil/pharmacy_sale",
    githubLink: "https://github.com/anasakil/pharmacy_sale",
  },
  {
    title: "Weather App",
    image: projectImage9,
    description: `The Weather App, built with Flutter and OpenWeather API, provides real-time weather data for any city, including temperature, humidity, and wind speed. It features an intuitive interface with location search and detailed forecasts.`,
    techstack: "FLUTTER , openweathermap",
    previewLink: "https://github.com/anasakil/flutter_weather",
    githubLink: "https://github.com/anasakil/flutter_weather",
  },
  {
    title: "E-commerce Artisan Moroccan",
    image: projectImage8,
    description: `This e-commerce platform, built for Moroccan artisans, allows sellers to create accounts, manage their products, and access a modern dashboard tailored for both sellers and admins. Users can browse products, filter by categories, and purchase using Stripe payment. Additionally, the app features a map integration (Leaflet), enabling users to view products based on geographic zones with just a click.`,
    techstack: "MERN STACK , stripe ,cloudanary ,antd ,react ,laeflet , swagger , ci/cd ,github action",
    previewLink: "https://github.com/anasakil/artisan",
    githubLink: "https://github.com/anasakil/artisan",
  },
  {
    title: "Gestion Internat",
    image: projectImage2,
    description: `Gestion Internat is a web platform where students can register online, and admins manage all student information. The platform allows admins to assign students to their respective classes, monitor their enrollment status, and maintain an organized system for student management`,
    techstack: "LARAVEL , html css bootstrap jquery",
  },
  {
    title: "Landing Page Arkx",
    image: projectImage6,
    description: `The Arkx Landing Page is a sleek and modern single-page website designed to showcase the services and solutions offered by Arkx. Built with a focus on user experience, it includes engaging visuals, clear calls to action, and responsive design for all devices. The landing page highlights key features, client testimonials, and contact information, driving conversions and improving brand visibility`,
    techstack: "html tailwind css ",
    previewLink: "https://anasakil.github.io/ark/",
    githubLink: "https://github.com/anasakil/ark",
  },
  {
    title: "flutter YouTube Downloader",
    image: projectImage9,
    description: `Built with Flutter and Flask, this application enables users to download MP4 videos and MP3 audio files efficiently. The Flutter frontend offers an intuitive, smooth interface, while the Flask backend ensures secure and reliable media downloads. Key features include fast download speeds, quality selection, offline access, and a seamless user experience designed for easy media management.`,
    techstack: "flutter , flask ",
    previewLink: "https://github.com/anasakil/flutter_YouTube_Downloader",
    githubLink: "https://github.com/anasakil/flutter_YouTube_Downloader",
  },
  {
    title: "Web Scraping Project with Scrapy",
    image: projectImage3,
    description: `This project leverages Scrapy to scrape data from various websites efficiently. It automates the extraction of large-scale data, including product listings, prices, and reviews, for e-commerce platforms and other sources. The data is processed and stored in a structured format like JSON or MongoDB, enabling seamless integration into further analysis or applications`,
    techstack: "python , scrapy  ",
  },
 
  {
    title: "wink-consulting.com",
    image: projectImage4,
    description: `A WordPress showcase website created for a consulting firm, highlighting services, expertise, and client success stories. The site is fully responsive, SEO-optimized, and includes sections for service details, case studies, and an integrated contact form for inquiries. Built with custom plugins for lead management and client interaction.`,
    techstack: "WordPress  ",
    previewLink: "https://wink-consulting.com/",
  },
  {
    title: "fruitforyou",
    image: projectImage5,
    description: `This WordPress-based platform helps avocado exporters manage product listings, inventory, and track shipments. Integrated with CMI for secure payments, the site offers exporters a comprehensive dashboard to oversee orders and shipments. Plugins for shipment tracking and logistics ensure smooth operations for exporting avocados internationally.`,
    techstack: "WordPress, CMI  ",
    previewLink: "https://fruitsforyou.ma/",
  },
  {
    title: "E-commerce Platform for Fashion Agency",
    image: projectImage7,
    description: `This e-commerce platform was built using WordPress, integrating CMI for payment processing. Designed for a fashion agency, it enables users to browse and purchase fashion items seamlessly. The platform includes a user-friendly dashboard for sellers to manage their products and orders. Key features include category filtering, product galleries, and secure payment through CMI`,
    techstack: "WordPress, WooCommerce, CMI, Custom Plugins  ",
    previewLink: "https://karimtassi.com/",
  },
    
];

